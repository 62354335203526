@font-face {
  font-family: 'smiles';
  src: url('/assets/fonts/smiles/smiles.eot');
  src: url('/assets/fonts/smiles/smiles.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/smiles/smiles.woff') format('woff'),
       url('/assets/fonts/smiles/smiles.ttf') format('truetype'),
       url('/assets/fonts/smiles/smiles.svg#smiles') format('svg');
  font-weight: normal;
  font-style: normal;
}

$main-color: #2695f8;

html {
  height: 100%;

  background: $main-color url('/assets/images/pattern_3.svg') no-repeat center left;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  min-height: 100%;

  background-color: transparent !important;
  background: -moz-linear-gradient(165deg, rgba(0, 0, 0, 0.7) 0%, transparent 100%);
  background: -webkit-linear-gradient(165deg, rgba(0, 0, 0, 0.7) 0%, transparent 100%);
  background: linear-gradient(165deg, rgba(0, 0, 0, 0.7) 0%, transparent 100%);

  font-size: 0.875rem;
  line-height: 1.4;
  font-family: "Poppins", sans-serif;
  color: #444;
}

[class*='icon-']:before {
  display: inline-block;
  font-family: 'smiles';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
.icon-smile_1:before { content:'\0041'; }
.icon-smile_2:before { content:'\0042'; }
.icon-smile_3:before { content:'\0043'; }
.icon-smile_4:before { content:'\0044'; }
.icon-smile_5:before { content:'\0045'; }
